<template>
   <div id="all">
      <div id="top">
        <h1>后台管理系统</h1>
        <h3 style="">欢迎：{{realName}}</h3>
        <h3 style=""><a href="#" @click="logout" style="color:#fff;">退出</a></h3>
      </div>
      <div id="bottom">
         <div id="aside">
            <ul>
               <li>用户管理
                  <ul>
                     <router-link to="/main_new/user" tag="li" class="myactive">用户列表</router-link>
                     <router-link to="/main_new/user/add" tag="li" class="myactive">添加用户</router-link>
                  </ul>
               </li>
            </ul>
         </div>
         <div id="main">
            <router-view></router-view>
         </div>
      </div>
   </div>
</template>

<script>
export default {
    computed:{
      realName(){
       return this.$store.state.Login.userName || "未登录"
      }
    },
    methods:{
      logout(){
        sessionStorage.removeItem("token")
        sessionStorage.removeItem("userInfo")
        this.$store.state.Login.userName = ""
        this.$router.replace({name:"login_new"})
      }
    }
}
</script>

<style scoped>
     #all{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      height: 100vh;
     }
     #top{
      background-color: #333;
      color:#fff;
      padding: 5px;
     }

    #bottom{
       display: flex;
       flex:auto;
    }
    #aside{
      width: 220px;
      background: #ccc;
    }
    #main{
      background: #fff;
      flex:auto;
    }
   .myactive{
      color:#f00;
    }
</style>
